import React, { useState, useLayoutEffect } from 'react';
import { Input } from 'antd';
import Button from './systemComponents/Button/Button';
import LoginLayout from './LoginLayout';
import { useNavigate } from 'react-router-dom';
import { userObj } from './util/func/userObj';
//import { userObj } from '@Util';
//import { Mixpanel } from '../../mixpanel';
//import { history } from './app/history';


function definePageText(resetRequested, email = 'your email') {
  if (resetRequested) {
    return {
      title: 'Check your inbox.',
      subtitle: `We sent password reset instructions to ${email}. Remember to check your spam or junk folder if you don't see it.`
    };
  };

  return {
    title: 'Forgot your password?',
    subtitle: 'We\'ll email you instructions on how to reset your password.'
  };
}

function requestPasswordReset(email) {
  const url = `${userObj().api}user/forgotPassword`;
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      email,
      app:'plus'
    })
  };

  return fetch(url, request);
}

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const isEmailValid = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
  const { title, subtitle } = definePageText(resetRequested, email);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    //if (location?.userEmail) {
    //  setEmail(location?.userEmail);
    //}
  }, []);

  const showTemporaryErrorMessage = (message) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(''), 5000);
  };

  const requestReset = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await requestPasswordReset(email);

      if (!response.ok) {
        setLoading(false);
        throw Error(response.statusText);
      };

      const jsonData = await response.json();

      if (!jsonData.result) {
        setLoading(false);
        showTemporaryErrorMessage('This is not a valid email address in our system.');

        return;
      };

      // password reset success
      //Mixpanel.identify(email);
      //Mixpanel.track('Forgot Password');
      setLoading(false);
      setResetRequested(true);
    } catch(error) {

      setLoading(false);
      setResetRequested(false);
      showTemporaryErrorMessage('There was an error with your request. Please try again later!');
    }
  };

  return (
    <LoginLayout
      title={ title }
      subtitle = { subtitle }
    >
      {!!resetRequested ? (
        <div className="forgotPassword-requested">
          <Button
            className="ant-btn-primary ant-btn-round"
            onClick={ () => navigate('/login') }
          >
            Go to Login
          </Button>
        </div>
      ) : (
        <form className="forgotPassword-form" onSubmit={ requestReset }>
          <label className="forgotPassword-form-label">
            Email
            <Input
              className={ !!errorMessage && 'warning' }
              type="text"
              name="userEmail"
              id="userEmail"
              value={ email }
              placeholder="Email"
              onChange={ event => setEmail(event?.target?.value) }
              disabled = { loading }
            />
          </label>
          <p className="login-warning forgot-password-warning">
            {errorMessage}
          </p>
          <div className="forgotPassword-form-footer">
            <Button
              className="ant-btn-default ant-btn-round"
              type="default"
              onClick={ () => navigate('/login') }
              disabled = { loading }
            >
              Go to Login
            </Button>
            <Button
              htmlType="submit"
              className="ant-btn-primary ant-btn-round"
              disabled={ loading || !isEmailValid }
            >
              Send Reset Link
            </Button>
          </div>
        </form>
      )}
    </LoginLayout>
  );
}

export default ForgotPassword;
