import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import reducer from './reducers';
import thunk from 'redux-thunk';

let composeEnhancers = compose;

const middleware = [
  thunk,
  promise
];

if (process.env.NODE_ENV === 'development') {
  //middleware.push(logger);
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || composeEnhancers;
}

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;