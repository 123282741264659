import lodash from 'lodash';
const storageKey = 'APP_ROUTE_HISTORY';

const initialState = {
  history: [],
  current: null
};

const saveState = state => {
  if (typeof window !== 'undefined') {
    try {
      window.sessionStorage.setItem(storageKey, JSON.stringify(state));
    } catch (e) {}
  }
};

const loadState = () => {
  let state = { ...initialState };

  if (typeof window !== 'undefined') {
    try {
      // @ts-ignore
      state = JSON.parse(window.sessionStorage.getItem(storageKey)) || state;
    } catch (e) {
      state = { ...initialState };
    }
  }
  
  return state;
};

const lastState = state => state.history.slice(-1)[0];

const pushState = (state, next) => {
  const history = state.history.slice(-25);
  const last = lastState(state);
  let nextState = { ...state };

  if (next.key && !lodash.isEqual(last, next)) {
    nextState = {
      history: [...history, next],
      current: next.key
    };
  }

  saveState(nextState);

  return nextState;
};

const trackedPaths = {
  '^/?(\\?.*)?$': '^/[a-zA-z0-9]*/?(\\?.*)?$'
};

const  BrowserHistory = (state = loadState(), action) => {
  let tracked;
  switch (action.type) {
  case 'URL_CHANGE':
    // Browser back/forward navigation
    if (action.payload.action === 'POP') {
      return {
        ...state,
        current: action.payload.key
      };
      // save/update 'tracked paths'
    // eslint-disable-next-line no-cond-assign
    } else if (tracked = Object.keys(trackedPaths).find((_) => RegExp(_, 'i').test(action.payload.pathname))) {
      return pushState(state, {
        key: action.payload.key,
        for: trackedPaths[tracked],
        path: `${action.payload.pathname}${action.payload.search}${action.payload.hash}`
      });
      // All other navigation (especially non-tracked pages that use the 'last tracked url')
    } else {
      return pushState(state, {
        ...lastState(state),
        key: action.payload.key
      });
    }

  default:
    return state;
  }
};

export default BrowserHistory;