const INITIAL_STATE = {
  quickFilter: '',
  windowSize: {
    height: Number,
    width: Number,
  },
  error: null,
  mergeView: false,
  viewOpen: true,
  modals: {
    messages:{
      opened: false,
      data: null,
    },
    userTheme:{
      opened: false,
      data: null,
    },
    forgotPassword:{
      opened: false,
      data: {},
    },
    licenseAgreement:{
      opened: false,
      data: {},
    },
    orderHistory:{
      opened: false,
      data: {},
    },
    notifications:{
      opened: false,
      data: {},
    },
    editHistory:{
      opened: false,
      data: {}
    },
  }
};

const App = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case 'URL_CHANGE':
    return {
      ...state,
      location: action.payload,
      modals: INITIAL_STATE.modals
    };

  case 'ERROR':
    return {
      ...state,
      error: action.payload,
      noPrefix: action.noPrefix
    };

  case 'SET_WINDOW_SIZE':
    return {
      ...state,
      windowSize: action.payload,
    };

  case 'FETCH_USER':
    return {
      ...state,
      ...action.isLoading,
    };

  case 'FETCH_USER_SUCCESS':
    return {
      ...state,
      ...action.payload
    };

  case 'SET_QUICK_FILTER':
    return {
      ...state,
      quickFilter: action.payload,
    };

  case 'SET_APP_VIEW':
    return {
      ...state,
      viewOpen: state.viewOpen ? false : true,
    };

  case 'SET_APP_VIEW_OPEN':
    return {
      ...state,
      viewOpen: true,
    };

  default:
    return state;
  }
};

export default App;
