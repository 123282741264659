import { get } from 'lodash';
import userObj from '../util/func/userObj';
import { showNotification } from '../actions/notification';
//import { logout } from './user/logout';

export function apiIsLoading(actionType, bool) {
  return {
    type: actionType,
    isLoading: bool
  };
}

export function apiDataSuccess(actionType, data, request) {
  return {
    type: actionType,
    payload: data,
    request
  };
}

export function apiHasErrored(errorMessage) {
  showNotification('error', 'Error', errorMessage);
  return false;
}

export function httpRequest_v2(path, request = {}) {
  const baseUrl = userObj.url;
  const isJSONBody = get(request.body, 'constructor.name') === 'Object';
  const controller = new AbortController();
  const requestObject = {
    method: 'GET',
    ...request,
    headers: {
      ...(!request.body || isJSONBody ? { // Intelligent defaults
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      } : {}),
      'apiKey': userObj().apiKey,
      'userId': userObj().userId,
      ...(request.headers || {}),
    },
    body: isJSONBody ? JSON.stringify(request.body) : request.body,
    signal: controller.signal
  };
  const url = path.indexOf('http') === 0 ? path : `${baseUrl}${path}`;

  const promise = fetch(url, requestObject)
    .then(async (response) => {
      if (!response.ok) {
        const error = Error(response.statusText);
        error.response = await response.json();
        throw error;
      }

      return response;
    })
    .then((response) => {
      return response.text().then((text) => {
        return text ? JSON.parse(text) : {};
      });
    });

  promise.cancel = () => controller.abort();

  return promise;
}

// TO DO: Replace with httpRequest_v2
export function httpRequest(url, request) {
  return fetch(url, request)
    .then(async (response) => {
      console.log(response);
      if (!response.ok) {
        const error = Error(response.statusText);
        error.response = await response.json();
        throw error;
      }
      return response;
    })
    .then((response) => response.json());
}

export function apiDispatch(httpRequest, ...dispatchProperties) {
  const [url, request, loading, success, errorMessage, log, callback] = dispatchProperties;

  return (dispatch) => {
    dispatch(apiIsLoading(loading, true));

    return httpRequest
      .then((data) => {
        dispatch(apiIsLoading(loading, false));
        if((data.error && data.error.includes('Unauthorized request'))
                 || (data.error && data.error[0] && data.error[0].includes('Unauthorized request'))) {
          //dispatch(logout());
        } else {
          dispatch(apiDataSuccess(success, data, { url, ...request }));
          if(typeof callback === 'function') {
            callback(true, { data }, dispatch);
          }
          // parse out the required tracking information from the log object.
          if(log) {
           // Mixpanel.track(log.name, mixpanelDataObjectParsing(log, data));
          }

          return data;
        }
      })
      .catch((err) => {
        const message = err.response && errorMessage ? errorMessage[err.response.errorType] || errorMessage.default || errorMessage : (errorMessage && errorMessage.default) || errorMessage;
        apiHasErrored(message);
        dispatch(apiIsLoading(loading, false));
        if(typeof callback === 'function') {
          callback(false, { err }, dispatch);
        }
        // parse out the required tracking information from the log object.
        if(log) {
          //Mixpanel.track(`${log.name} Error`, log.params);
        }

        throw err;
      });
  };
}

export function apiFetch(url, request, loading, success, errorMessage, log, callback) {
  return apiDispatch(httpRequest(url, request), url, request, loading, success, errorMessage, log, callback);
}

export function apiFetch_v2(url, request, action, errorMessage, log, callback) {
  const loading = `${ action }_LOADING`;
  const success = `${ action }_SUCCESS`;

  return apiDispatch(httpRequest_v2(url, request), url, request, loading, success, errorMessage, log, callback);
}