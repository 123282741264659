const INITIAL_STATE = {
  notifications: [],
  toasts: [],
  modalNotifications: [],
  status: true,
};

const Notification = (state = INITIAL_STATE, action) => {
  switch (action.type) {

  case 'USER_NOTIFICATIONS_SUCCESS':
    return {
      ...state,
      modalNotifications: action.payload.notifications,
    };

  case 'USER_NOTIFICATION_SUCCESS':
    return {
      ...state,
      notifications: action.payload.notifications,
      status: true,
    };

  case 'USER_NOTIFICATION_STATUS':
    return {
      ...state,
      status: action.payload,
    };
  default:
    return state;
  }
};

export default Notification;