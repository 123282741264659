import moment from 'moment';

export function userObj() {
  const offset = moment().utcOffset();
  const api = 'https://plus-api.showseeker.com/';
  const url = 'https://plus.showseeker.com/goplus/';   
  const userInfo = localStorage.getItem('loginAuth');
  const userObj = (userInfo !== null) ? JSON.parse(userInfo) : {userId:0,apiKey:0,authExpirationTime:0};
  
  return {
    userId:userObj.userId,
    apiKey: userObj.apiKey,
    api: api,
    url: url,
    tzOffset: offset,
    authExpirationTime : userObj.authExpirationTime
  };
}

export default userObj;