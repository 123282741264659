import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import './antd-overrides.less';
import './assets/fontawesome/css/all.min.css';
import Login from './Login';
import ForgotPassword from './ForgotPassword'
import PasswordReset from './PasswordReset'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './app/store';
import { userObj } from './util/func/userObj';





const {userId, apiKey, url, authExpirationTime} = userObj();
const currentTime = new  Date().getTime();


if(authExpirationTime !== '0' && currentTime > authExpirationTime){
  localStorage.removeItem('loginAuth');
}
else if (userId !== '0') {
  setTimeout(function(){window.location.href=url+"auth/redirect.php?id="+userId+"&tokenId="+apiKey+"&expiryTime="+authExpirationTime+"&new="+Math.random() },1);
}



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <div id="page-router">
          <Routes>
            <Route path="*" element={ <Login/> } />
            <Route exact path="/login" element={ <Login/> } />
            <Route path="/forgotPassword" element={ <ForgotPassword/> } />
            <Route exact path="/reset/:token" element={ <PasswordReset/> } />
          </Routes>
        </div>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
