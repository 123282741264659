const LocalStorageKey = 'AuthenticatedAdmin';

export function getAdmin() {
  try {
    return JSON.parse(localStorage.getItem(LocalStorageKey) || '{}');
  } catch(e) {
    return {};
  }
}

export function setAdmin(admin, reload=true) {
  if (admin) {
    /*localStorage.setItem(LocalStorageKey, JSON.stringify({ id: admin.id, apiKey: admin.apiKey }));
    localStorage.setItem('userId', admin.id)
    localStorage.setItem('apiKey', admin.apiKey)*/
  } else {
    localStorage.removeItem(LocalStorageKey)
    localStorage.removeItem('userId')
    localStorage.removeItem('apiKey')
  }

  reload && window.location.reload();
}

function getMinsDelta(){
  const currentTime = Date.now();
  if(! localStorage.getItem('loginAttemptTime'))
      localStorage.setItem('loginAttemptTime', currentTime);
      
  const initialTime = localStorage.getItem('loginAttemptTime') ?? currentTime;
  let diffMins = Math.round(((currentTime - initialTime % 86400000) % 3600000) / 60000);  
  
  return diffMins;
}

export function setLoginCount() {

    let diffMins = getMinsDelta();
    const currentTime = Date.now();
    const pastAttemptCount = localStorage.getItem('loginAttemptCount') ?? 0;
    
    if(diffMins <= 10){
        localStorage.setItem('loginAttemptCount', parseInt(pastAttemptCount) + 1);
    }
    if(diffMins > 10 && pastAttemptCount <= 6){
        localStorage.setItem('loginAttemptTime', currentTime);
    }
    if(diffMins > 30 && pastAttemptCount > 6){
      clearLoginAttempts();
    }
}

export function getLoginCount() {
    return localStorage.getItem('loginAttemptCount'); 
}

export function isLockout() {
    const diffMins = getMinsDelta();
    const pastAttemptCount = localStorage.getItem('loginAttemptCount') ?? 0;
    
    if(diffMins > 20 && pastAttemptCount > 6){
      clearLoginAttempts();
      return false;
    }
    
    return (getLoginCount() >  6 ) ? true : false;
}

export function clearLoginAttempts() {
    localStorage.removeItem('loginAttemptCount');
    localStorage.removeItem('loginAttemptTime');
}


export function isAuthenticated(admin) {
  return Boolean(admin && admin.id && admin.apiKey);
}

export function requestHeaders() {
  const { id, apiKey } = getAdmin();

  return {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      userId: id,
      apiKey
    }
  };
}
