import React from 'react';
import setPageTitle from './util/hooks/setPageTitle';
import './Login.scss';
export const themeLoginLogo = process.env.REACT_APP_THEME_LOGIN_LOGO;
export const themeBackground = process.env.REACT_APP_THEME_BACKGROUND;
export const themeBackgroundPlacement = process.env.REACT_APP_THEME_BACKGROUND_PLACEMENT;
export const themeBackgroundColor = process.env.REACT_APP_THEME_BACKGROUND_COLOR;

//set the background image and background settings
const getLoginTheme = () => {
  let theme = {
    backgroundColor: themeBackgroundColor,
  };

  if(themeBackgroundPlacement.includes('CENTER')){
    if(themeBackground !== 'NONE'){
      theme.backgroundImage = 'url(' + themeBackground + ')';
    };
    theme.backgroundPosition = 'left 40%';
    theme.backgroundSize = 'auto 502px';
    theme.backgroundRepeat = 'repeat-x';
  };

  if(themeBackgroundPlacement.includes('FULL')){
    if(themeBackground !== 'NONE'){
      theme.backgroundImage = 'url(' + themeBackground + ')';
      theme.backgroundPosition = 'center';
      theme.backgroundSize = 'cover';
      theme.backgroundRepeat = 'no-repeat';
      theme.width = '100vw';
      theme.height = '100vh';
    };
  };

  return theme;
};

//todo- make this more flexible
const getLoginLinks = () => {
  if(themeBackgroundPlacement.includes('CENTER')){
    return '#1A7CD3';
  }else{
    return '#ffffff';
  }
};

//to handel the different logo sizes when appending Pilot
const getLoginLogoSize = () => {
  if(themeBackgroundPlacement.includes('CENTER')){
    return '158px';
  }else{
    return '344px';
  }
};

function LoginLayout ({
  title = '',
  subtitle = '',
  children
}) {
  setPageTitle('Login | ShowSeeker');

  return (
    <div className="login-container" style={ getLoginTheme() }>
      <div className="login-info-container">
        <img style={ { width:getLoginLogoSize() } } className="login-logo" alt="ShowSeeker Plus Logo" src={ themeLoginLogo } />
        <h1>{ title }</h1>
        <h3>{ subtitle }</h3>
        { children }
      </div>
      <ul className="login-footer">
        <li>
          <a
            className="login-link-style"
            href="https://www.showseeker.com/terms-of-service"
            rel="noopener noreferrer"
            target="_blank"
            style={ { color:getLoginLinks() } }
          >
            Terms of Service
          </a>
        </li>
        <li>
          <a
            className="login-link-style"
            href="https://www.showseeker.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
            style={ { color:getLoginLinks() } }
          >
            Privacy Policy
          </a>
        </li>
        <li>
          <a
            className="login-link-style"
            href="https://www.showseeker.com/"
            rel="noopener noreferrer"
            target="_blank"
            style={ { color:getLoginLinks() } }
          >
            ShowSeeker.com
          </a>
        </li>
      </ul>
    </div>
  );
};

export default LoginLayout;
