import React from 'react';
import cx from 'classnames';
import { Button } from 'antd';

const LoginButton = ({ type='primary', shape='', size='default', className='', disabled=false, loading=false, ...props }) => {
  // Removing invalid props. Spreading props and passing around is throwing warnings.
  const buttonProps = { ...props };
  delete buttonProps.link;

  return (
    <Button
      type={ type }
      shape={ shape }
      size={ size }
      className={ cx('ss-btn', className) }
      disabled={ disabled || loading }
      loading={ loading }
      { ...buttonProps }
    />
  ); };

export default LoginButton;