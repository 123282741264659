import React from 'react';
import { notification } from 'antd';

//set the description for the notification. This can an array or a string
class Description extends React.Component {
  render() {
    const arr = Array.isArray(this.props.data);

    return (
          <div>
              {!arr? <span>{ this.props.data }</span> : null }
              {arr? <span>{ this.props.data.map((object, i) => (<div key={ i }>{object}</div>)) }</span> : null }
          </div>
    );
  }
}

//show a basic notification
// eslint-disable-next-line
export const showNotification = (notificationType, message, description=null, key=null, duration=15, btn = null) => {
  notification[notificationType]({
    message: message,
    placement: 'bottomRight',
    duration,
    description: <Description data={ description } />,
    key: key,
    btn,
  });
};

//close a natification based on the notification key
export const notificationCloseFromKey = (key) => {
  notification.close(key);
};