import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import Button from '../src/systemComponents/Button/Button';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import LoginLayout from './LoginLayout';
import { login as loginAction } from './actions/user/login';
import { isLockout } from './util/helper';
import { userObj } from './util/func/userObj';

function Login () {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authenticationFailed = useSelector(state => state?.user?.failed);
  const loginCount = useSelector(state => state?.user?.loginAttemptCount);
  const dispatch = useDispatch();
  const emptyField = email.length === 0 || password.length === 0 || isLockout();
  

  const authenticate = event => {
    event.preventDefault();
    const userLocalData = userObj();
    dispatch(loginAction(userLocalData, email, password));
  };
  
  return (
    <LoginLayout
      title="Hello. Come on in."
      subtitle={ 'Sign in to ShowSeeker Plus\u00ae' }
    >
      <form className="login-form" onSubmit={ authenticate }>
        <p className="login-form-warning login-warning">
          {authenticationFailed && !isLockout() && 'Invalid username or password.'}
          {(isLockout() || loginCount > 6) && 'The account is temporarily locked.'}
        </p>
        <label className="login-form-label">
          Email
          <Input
            type="text"
            name="userEmail"
            id="userEmail"
            defaultValue=""
            placeholder="Email"
            onChange={ event => setEmail(event?.target?.value) }
          />
        </label>
        <label className="login-form-label">
          Password
          <Input.Password
            placeholder="Password"
            name="userPassword"
            id="userPassword"
            defaultValue=""
            onChange={ event => setPassword(event?.target?.value) }
            iconRender={ visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />) }
          />
        </label>
        <NavLink
          className="login-link-style login-forgot-password"
          to={ {
            pathname: '/forgotPassword',
            userEmail: email
          } }
        >
          Forgot Password?
        </NavLink>
        <div className="login-form-footer">
          <p className="login-form-tos">
            By logging in you agree to our{' '}
            <a
              className="login-form-tos-link"
              href="https://www.showseeker.com/terms-of-service"
              rel="noopener noreferrer"
              target="_blank"
            >Terms of Service
            </a>
            .
          </p>
          <Button
            htmlType="submit"
            className="ant-btn-primary ant-btn-round"
            disabled={ emptyField}
          >
            Sign In
          </Button>
        </div>
      </form>
    </LoginLayout>
  );
};

export default Login;
