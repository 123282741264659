/**
 * @param {string} keyName - A key to identify the value.
 * @param {any} keyValue - A value associated with the key.
 * @param {number} expiryTime- Time to live in seconds.
 */
 export const set = (keyName, keyValue, expiryTime) => {
    const data = {
        value: keyValue,                  // store the value within this object
        authExpirationTime: expiryTime,   // store the epiration Time (time to live)
    }
 
    // store data in LocalStorage 
    localStorage.setItem(keyName, JSON.stringify(data));
};


/**
 * @param {string} keyName - A key to identify the data.
 * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
 */
 export const get = (keyName) => {
    const data = localStorage.getItem(keyName);
    if (!data) {     // if no value exists associated with the key, return null
        return null;
    }
 
    const item = JSON.parse(data);
 
    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item?.authExpirationTime) {
        localStorage.removeItem(keyName);
        return null;
    }
 
    // return data if not expired
    return item.value;
};

/**
 * @returns { number} returns tomorrow's date at 2am in milliseconds
 */
export const expiryTime = () => {
    var expirationTime = new Date();
    expirationTime.setHours(0,0,0,0);
    expirationTime.setTime(expirationTime.getTime() + (26*60*60*1000));
    return expirationTime.getTime();
}