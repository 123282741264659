import { combineReducers } from 'redux';
import app from '../reducers/app';
import browserHistory from '../reducers/browserHistory';
import notifications from '../reducers/notifications';
import user from '../reducers/user';


export default combineReducers({
  app,
  browserHistory,
  notifications,
  user
  }, combineReducers);
