import { apiIsLoading, apiDataSuccess, apiHasErrored } from '../api';
import { clearLoginAttempts } from '../../util/helper';
import { expiryTime } from '../../util/localStorage';
import mixpanel from 'mixpanel-browser';
mixpanel.init('f31b55960de822b6d8db5d6897390319');

export function checkLogin(actionType, data) {
  var valid = false;
  if(data.apiKey && data.id){
    valid = true;
  }

  return {
    type: actionType,
    valid: valid,
    payload: data,
  };
}


export function login(userObj, email, password) {
  let xrlBase = userObj.api;
  let xrl = xrlBase + 'user/login'
  let request = {
    method: 'POST',
    crossDomain:true,
    processData:false,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      email: email,
      password: password,
      location: 1,
      roleId:14
    })
  };

  let mixpanelData = {};
  mixpanelData.email = email;
  mixpanelData.password = password;
  mixpanelData.appName = 'Plus-Login';
  mixpanelData.userIp = '0.0.0.0';

  return (dispatch) => {
    dispatch(apiIsLoading('USER_IS_LOGGING_IN', true));
    
    fetch(xrl, request)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        dispatch(apiIsLoading('USER_IS_LOGGING_IN', false));

        return response;
      })
      .then((response) => response.json())
      .then((data) => dispatch(checkLogin('USER_LOGIN_CHECK', data)))
      .then((data) => {
        if (!data.valid) {
          dispatch(apiIsLoading('USER_LOGIN_FAILED', true));

          mixpanel.people.set({ $email: email });
          mixpanel.track('Login Error', mixpanelData);

          return;
        }else{
          
          mixpanel.identify(data.payload.id);
          mixpanel.people.set({ $email: email });
          mixpanel.track('Login', mixpanelData);

          dispatch(apiDataSuccess('USER_LOGIN_SUCCESS',data.payload));

          clearLoginAttempts();
          setTimeout(function(){window.location.href=userObj.url+"auth/redirect.php?id="+data.payload.id+"&tokenId="+data.payload.apiKey+"&expiryTime="+expiryTime()+"&new="+Math.random() },1);

          return data;

        }
      })
      .catch(() => {
        apiHasErrored('Login Error');
        dispatch(apiIsLoading('USER_IS_LOGGING_IN', false));
      });
  };
}