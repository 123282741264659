import { setLoginCount } from '../util/helper';
import { expiryTime } from '../util/localStorage';

const INITIAL_STATE = {
  cacheKey: Date.now(),
  date: Date(),
  isLoading: true,
  failed : false,
  userId: 0,
  apiKey: String,
  loginAttemptCount:localStorage.getItem('loginAttemptCount'),
  location:{ pathname:'/' }
 }
 
 const User = (state = INITIAL_STATE, action) => {
 
   switch (action.type) {
   case 'USER_AUTHENTICATED':
     return {
       ...state,
       userId: Number(action.payload.userId),
       apiKey: action.payload.apiKey,
     };
 
   case 'USER_FETCH_BUSINESS_CATEGORIES_SUCCESS':
     return {
       ...state,
       businessCategories: action.payload.businessCategories,
     };
 
   case 'USER_LOGOUT':
     return {
       ...state,
       date: action.payload,
       userId: Number,
       apiKey: String,
     };
 
   case 'USER_LOGIN_SUCCESS':
    const userInfo = {
      "userId" : action.payload.id,
      "apiKey" : action.payload.apiKey,
      "admin" : "0",
      "isLogin":"1",
      "authExpirationTime" : expiryTime()
    }
    localStorage.setItem("loginAuth",JSON.stringify(userInfo));
     // MixPanel
     //Mixpanel.identify(action.payload.id);
     //Mixpanel.track('Login');
 
     return {
       ...state,
       userId: Number(action.payload.id),
       apiKey: action.payload.apiKey,
     };
    case 'FETCH_USER_INFO_SUCCESS':
    case 'SAVE_USER_SUCCESS':
     return {
      ...state,
      cacheKey: Date.now(),
      userInfo: { ...state.userInfo, ...action.payload }
      };

   case 'URL_CHANGE':
    return {
     ...state,
     location: action.payload
    };

   case 'SET_USER_THEME':
     return {
      ...state,
      selectedTheme: action.payload
    };

   case 'USER_LOGIN_FAILED':
    setLoginCount();
     // MixPanel
     //Mixpanel.track('Login Error');

     return {
      ...state,
      failed: true,
      loginAttemptCount:localStorage.getItem('loginAttemptCount')
      };

   default:
    return state;
  }
};

export default User;