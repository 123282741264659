import React, { useState }  from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import Button from './systemComponents/Button/Button';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
//import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';

import './PasswordReset.css';
//import { storeValue } from './actions/storeValue';
import { userObj } from './util/func/userObj';
const Logo = 'https://showseeker.s3.amazonaws.com/images/showseeker_login.png';


function PasswordReset (){

  const validationChecklistL = [
    {
      message: '8 to 25 characters long',
      check: (password) =>
        password && password.length >= 8 && password.length <= 25
    },
    {
      message: 'At least one upper case character',
      check: (password) => password && /[A-Z]/.test(password)
    },
    {
      message: 'At least one lower case character',
      check: (password) => password && /[a-z]/.test(password)
    }
  ];


  const validationChecklistR = [
    {
      message: 'No consecutive repeating characters',
      check: (password) => password && !/(.)\1/.test(password)
    },
    {
      message: 'At least one number',
      check: (password) => password && /[0-9]/.test(password)
    },
    {
      message: 'At least one special character',
      check: (password) =>
        password && /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)
    }
  ];
  
  const validationCheck = [
    {
      check: (password) =>
        password && password.length >= 8 && password.length <= 25
    },
    {
      check: (password) => password && /[A-Z]/.test(password)
    },
    {
      check: (password) => password && /[a-z]/.test(password)
    },
    {
      check: (password) => password && !/(.)\1/.test(password)
    },
    {
      check: (password) => password && /[0-9]/.test(password)
    },
    {
      check: (password) =>
        password && /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(password)
    }
  ];


  let params = useParams();
  const logo = Logo;
  const [state, setState] = useState({
    success: null,
    password: '',
    passwordConfirm: '',
    message: null,
    isDisabled: true
  });
  



  const showMessage = (message) => {
    clearTimeout(clearMessage());
    setState({...state,'message':message} );

    function clearMessage (){
      setTimeout(() => setState({...state, ...{'message':null}}) , 
      4000);
    }
  };

  const submit = ()  => {
    const token = params.token;
    const password = state.password;
    const passwordConfirm = document.getElementById('passwordConfirm').value;

    if (validationCheck.find(({ check }) => !check(password))) {
      showMessage('Password is invalid.');

      return;
    } else if (password !== passwordConfirm) {
      showMessage('Passwords must match.');

      return;
    }

    const url = `${userObj().api}user/passwordReset`;
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        token,
        password
      })
    };

    fetch(url, request)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((data) => {
        if (data?.error === 'Previous password cannot be reused') {
          showMessage('Password must be different from your current one.');

          return;
        }

        if (data?.error === 'Password is not secure.') {
          showMessage('Password is not secure enough.');

          return;
        }

        if (data?.error === 'Invalid token') {
          showMessage('The current password reset token is invalid.');

          return;
        }

        if (Boolean(data?.result)) {
          redirect();
        }
      })
      .catch(() => {
        showMessage(
          'There was an error with your request. Please try again later!'
        );
      });
  }

  const redirect = () => {
    localStorage.removeItem('userId')
    localStorage.removeItem('apiKey')
    localStorage.removeItem('loginAttemptCount');
    localStorage.removeItem('loginAttemptTime');    
    window.location.href= '/login';
  };


    let password = state.password;

    return (
      <div className="row login">
        <div className="backgroundBox col-12">
          <div className="container">
            <div
              className="justify-content-center row align-items-center"
              style={{ paddingTop: '18vh' }}
            >
              <div className="loginBox flex-column">
                <img alt="Logo" height="44" src={ logo } />

                <div className="loginGreeting">Reset your password.</div>

                <div className="loginFormLabel">New Password</div>

                <div>
                  <Input.Password
                    placeholder="New Password"
                    name="userPassword"
                    className="font-weight-light"
                    id="userPassword"
                    defaultValue=""
                    onChange={(e) =>
                      setState({...state, ...{ password: e.target.value }})
                    }
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>

                <div className="loginFormLabel">Confirm Password</div>
                <div>
                  <Input.Password
                    placeholder="Confirm Password"
                    name="passwordConfirm"
                    className="font-weight-light"
                    id="passwordConfirm"
                    defaultValue=""
                    onChange={(f) =>
                      setState({...state, ...{ passwordConfirm: f.target.value }})
                    }
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </div>

                {state.message ? (
                  <div className="row">
                    <div className="col-12 error">{state.message}</div>
                  </div>
                ) : null}
                <div className="row validations">
                  <div className="col-6">
                    {validationChecklistL.map(({ message, check }) => (
                      <div>
                        <i
                          className="fas fa-check-circle"
                          style={{
                            color: check(password) ? '#0d6fd0' : '#d8d9da'
                          }}
                        ></i>
                        &nbsp;
                        {message}
                      </div>
                    ))}
                  </div>
                  <div className="col-6">
                    {validationChecklistR.map(({ message, check }) => (
                      <div>
                        <i
                          className="fas fa-check-circle"
                          style={{
                            color: check(password) ? '#0d6fd0' : '#d8d9da'
                          }}
                        ></i>
                        &nbsp;
                        {message}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Button
                      type="link"
                      className="ant-btn ss-btn ant-btn-primary ant-btn-round leftButtonFP"
                      onClick={redirect}
                    >
                      Go to Login
                    </Button>
                    <Button
                      color="secondary"
                      size="sm"
                      disabled={!state.passwordConfirm}
                      className="btn-sm-round rightButtonFP "
                      onClick={() => submit()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="justify-content-center row align-items-center"
              style={{ paddingTop: '24px' }}
            >
              <div className="col-2 bottomLinks">
                <a
                  href="https://www.showseeker.com/terms-of-service"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </div>
              <div className="col-2 bottomLinks">
                <a
                  href="https://www.showseeker.com/privacy-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
              <div className="col-2 bottomLinks">
                <a
                  href="https://www.showseeker.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  ShowSeeker.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

}

/*const mapDispatchToProps = (dispatch) => ({
  storeValue: bindActionCreators(storeValue, dispatch)
});*/

export default connect(null, null)(PasswordReset);
