/* eslint-disable react-hooks/rules-of-hooks */
import { useLayoutEffect } from 'react';

export function setPageTitle(title) {
  useLayoutEffect(() => {
    if (!!title) {
      document.title = title;
    }
  });
};

export default setPageTitle;
